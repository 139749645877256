/* src/App.css */
body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #f9f9f9; /* Update this color to match your logo's background */
}

.App {
  text-align: center;
}

.navigation {
  background-color: #282c34; /* Update this color to match your logo's background */
  padding: 10px 0;
}

.navigation ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center; /* Center align items vertically */
}

.navigation li {
  margin: 0 15px;
}

.navigation a {
  color: white;
  text-decoration: none;
  font-size: 18px;
}

.navigation a:hover {
  text-decoration: underline;
}

.home, .about, .services, .contact {
  padding: 20px;
}

.footer {
  background-color: #282c34;
  color: white;
  padding: 10px 0;
  position: fixed;
  width: 100%;
  bottom: 0;
}

a {
  color: #61dafb;
}

a:hover {
  color: #21a1f1;
}

.logo {
  height: 50px; /* Adjust height as needed */
  margin-right: 20px;
}
